import React from 'react';
import { Link } from 'gatsby';
import { Row, Col, Button } from 'react-bootstrap';
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import ArticleMeta from './../../articleMeta';
import FeatureWrapper from './styled';


const FeatureArticle = ({ slug, frontmatter }) => {

  const {
    title,
    description,
    date,
    category,
    tags,
    heroImage,
  } = frontmatter;

  const featureImage = getImage(heroImage);
  const articleLink  = `/${slug}`;

  return (
    <FeatureWrapper>
      <Row>
        <Col md={4}>
          <GatsbyImage image={featureImage} alt={title} />
        </Col>
        <Col>
          <h2 className="title"><Link to={articleLink}>{title}</Link></h2>
          <ArticleMeta category={category} date={date} tags={tags} />
          <p className="description">{description}</p>
          <Button className="more" href={articleLink}>อ่านต่อ...</Button>
        </Col>
      </Row>
    </FeatureWrapper>
  );
};


export default FeatureArticle;
