import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const FeatureWrapper = styled(Container)`
  border-bottom: 1px solid #eee;
  margin-top: 3rem;
  padding:0px;
  padding-bottom: 2rem;

  h2 > a {
    color: var(--color-pallate2);
    font-size: 1.7rem;
    font-weight: bold;
  }

  h2 {
    line-height: 1.7rem;
    @media (min-width: 768px) {
      margin-top: 0px; 
    }
  }

  .description {
    margin-top: 1.2rem;
  }

  .more {
    background-color: var(--color-pallate2);
    color: var(--color-pallate3);
    font-weight: bold;
    border: 1px solid #ccc;
    font-size: 0.8rem;
    :hover {
      color: #fff;
    }
  }
`;

export default FeatureWrapper;
